import axios from 'axios';
import {
  handleAxiosError,
} from '@/helpers';
import {
  IAppUseStore,
  useStore,
} from '@/store';
import {
  IGetConversationsData,
} from '@/type';

let conversationHistoryService: ConversationHistoryService | undefined;
let conversationHistoryStore: IAppUseStore['conversationHistory'];

interface IGetConversationHistoryParams {
  pageSize: number;
  pageNo: number;
}

const INITIAL_HISTORY_LIMIT = 10;

export class ConversationHistoryService {
  private async _fetchConversationHistory(
    pageNo: number,
    pageSize: number,
    createdBefore?: string,
  ): Promise<IGetConversationsData> {
    let fetchedConversationResponse: IGetConversationsData = {
      conversations: [],
      totalData: 0,
    };

    const { userId } = useStore.auth;
    if (!userId) {
      return fetchedConversationResponse;
    }

    try {
      conversationHistoryStore.isLoading = true;

      const params: IGetConversationHistoryParams = {
        pageSize,
        pageNo,
        ...(createdBefore && {
          createdBefore,
        }),
      };

      const response = await axios.get<IGetConversationsData>(
        `/conversations/list/user/${userId}`,
        {
          params,
        },
      );

      fetchedConversationResponse = response.data;
    } catch (e: any) {
      handleAxiosError(e);
    } finally {
      conversationHistoryStore.isLoading = false;
    }

    return fetchedConversationResponse;
  }

  async loadConversationHistory(createdBefore?: string): Promise<void> {
    if (conversationHistoryStore.isLoading || !conversationHistoryStore.hasMore) {
      return;
    }

    try {
      const fetchedConversationResponse = await this._fetchConversationHistory(
        conversationHistoryStore.currentPage,
        INITIAL_HISTORY_LIMIT,
        createdBefore,
      );

      const { conversations, totalData } = fetchedConversationResponse;

      if (conversations.length) {
        conversationHistoryStore.conversationList.push(...conversations);

        const { conversationList, newChatCountAfterMount } = conversationHistoryStore;

        const loadedBackendHistoryData = Math.abs(conversationList.length - newChatCountAfterMount);

        conversationHistoryStore.hasMore = loadedBackendHistoryData < totalData;

        if (conversationHistoryStore.hasMore) {
          conversationHistoryStore.currentPage += 1;
        }

        conversationHistoryStore.showHistoryList = true;
      } else {
        conversationHistoryStore.hasMore = false;
      }
    } catch (e: any) {
      handleAxiosError(e);
    }
  }
}

export function getConversationHistoryService(): ConversationHistoryService {
  if (conversationHistoryService) {
    return conversationHistoryService;
  }

  conversationHistoryStore = useStore.conversationHistory;

  conversationHistoryService = new ConversationHistoryService();

  return conversationHistoryService;
}
