import {
  defineStore,
} from 'pinia';

export const useUtilStore = defineStore('util', {
  state: () => ({
    isSupportOn: false,
    isLeftNavigationMenuOn: false,
    supportId: -1,
  }),
});
