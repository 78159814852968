<template>
  <div class="mx-auto max-w-xl p-12 py-3">
    <div
      class="text-center"
    >
      <h1 class="text-2xl font-semibold">
        {{ title }}
      </h1>
      <p
        class="mt-3"
      >
        Feel free to say anything you want to know or say. We’ll get back to you as soon as possible
      </p>
    </div>
    <Form
      #default="{ isSubmitting: isValidatingForm }"
      class="mx-auto my-6 pb-20"
      :validationSchema="schema"
      :validationContext="schema"
      @submit="onSubmit"
    >
      <InputHelper
        required
        name="email"
        :value="email"
        type="email"
        label="Your email"
        placeholder="Email Address"
      />
      <InputHelper
        name="phoneNo"
        type="text"
        label="Phone number"
        :value="phoneNo"
        placeholder="Phone Number"
      />
      <TextAreaHelper
        name="message"
        type="text"
        label="What would you like to talk about?"
        :value="message"
        placeholder="Write your message"
      />
      <div
        class="flex w-full gap-x-2 text-white"
      >
        <button
          class="btn w-1/2 bg-gray-400 text-lg text-white hover:bg-red-600"
          :disabled="isSubmitting || isValidatingForm"
          type="button"
          @click="utilStore.isSupportOn = false"
        >
          Cancel
        </button>
        <button
          class="btn w-1/2 bg-blue-1 text-xl text-white hover:bg-blue-2"
          :disabled="isSubmitting || isValidatingForm"
          type="submit"
        >
          Send
        </button>
      </div>
    </Form>
  </div>
</template>

<script lang="ts" setup>

import axios from 'axios';
import type {
  SendSupportMailDto,
} from 'dto';
import {
  Form,
} from 'vee-validate';
import {
  computed,
  onMounted,
  ref,
} from 'vue';
import {
  object,
  string,
} from 'yup';
import InputHelper from '@/components/Helper/InputHelper.vue';
import TextAreaHelper from '@/components/Helper/TextAreaHelper.vue';
import {
  handleAxiosError,
  supportMenuList,
} from '@/helpers';
import {
  useStore,
} from '@/store';

const utilStore = useStore.util;
let conversationId = '';

const email = ref('');
const phoneNo = ref('');
const message = ref('');

const title = computed(() => {
  const support = supportMenuList.find((item) => item.id === utilStore.supportId);
  return support ? support.text : '';
});

const isSubmitting = ref(false);

interface IFormSubmitValues {
  email: string;
  phoneNo: string;
  message: string;
}

const schema = computed(() => object().shape({
  email: string()
    .label('Your email')
    .email(),

  phoneNo: string()
    .label('Phone number')
    .nullable()
    .test('is-valid-phone', 'Phone number must be a valid E.164 number', (value) => {
      if (!value) {
        return true;
      }

      const trimmedValue = value.trim();
      return /^\+[1-9]\d{1,14}$/.test(trimmedValue);
    })
    .optional(),

  message: string()
    .label('Message'),
}));

async function onSubmit(values: IFormSubmitValues): Promise<void> {
  if (isSubmitting.value) {
    return;
  }

  try {
    isSubmitting.value = true;

    const payload: SendSupportMailDto = {
      email: values.email,
      ...(values.phoneNo && {
        phone: values.phoneNo,
      }),
      ...(values.message && {
        message: values.message.replace(/(?:\r\n|\r|\n)/g, '<br>'),
      }),
      ...(conversationId && {
        conversationId,
      }),
    };

    const response = await axios.post('/support/mail', payload);

    /**
     * TODO:
     * 1. show the response message to user
     * 2. Clear the input fields
     */
    // TODO: show the response message to user
  } catch (e: any) {
    handleAxiosError(e);
  } finally {
    isSubmitting.value = false;
  }
}

onMounted(() => {
  const { uuid } = useStore.conversationThread;

  if (uuid) {
    conversationId = uuid;
    useStore.conversationThread.uuid = '';
  }
});

</script>
