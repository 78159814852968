<template>
  <div
    v-if="!conversationHistoryStore.conversationList.length"
    class="mx-auto w-8/12"
  >
    <img
      :src="require('@/assets/empty.png')"
      alt="empty chat history"
    />
    <p
      class="text-center text-lg font-bold"
    >
      Empty chat history!
    </p>
  </div>
  <div
    v-else
  >
    <InfiniteScroll
      @load="loadConversationHistory"
    >
      <template
        #top
      >
        <div
          v-for="conversation in conversationHistoryStore.conversationList"
          :key="conversation._id"
          class="w-full p-3"
        >
          <div
            class="rounded-lg bg-[#eaeaea] shadow-sm"
          >
            <button
              type="button"
              class="w-full overflow-hidden px-4 py-2 text-start hover:rounded-lg hover:bg-[#d8d8d8]"
              @click="emits('conversation:id', conversation._id)"
            >
              <p
                v-if="conversation.createdAt"
                class="text-xm mb-2"
              >
                {{ dayjs(conversation.createdAt).format('LLL') }}
              </p>
              <h1
                class="truncate text-lg font-medium"
              >
                {{ conversation.query }}
              </h1>
              <p
                class="my-1 truncate"
              >
                {{ conversation.completionText }}
              </p>
            </button>
          </div>
        </div>
      </template>
    </InfiniteScroll>
  </div>
</template>
<script lang="ts" setup>
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import {
  onMounted,
} from 'vue';
import InfiniteScroll from '@/components/Helper/InfiniteScroll.vue';
import {
  type ConversationHistoryService,
  getConversationHistoryService,
} from '@/services/ConversationHistoryService';
import {
  useStore,
} from '@/store';

dayjs.extend(localizedFormat);

const emits = defineEmits<{
  (e: 'conversation:id', value: string): void;
}>();

const conversationHistoryStore = useStore.conversationHistory;

let conversationHistoryService: ConversationHistoryService;
const historyInitialLoadAt: string = new Date().toISOString();

async function loadConversationHistory(): Promise<void> {
  await conversationHistoryService.loadConversationHistory(historyInitialLoadAt);
}

onMounted(() => {
  conversationHistoryService = getConversationHistoryService();
  loadConversationHistory();
});
</script>
