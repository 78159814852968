/*
 * Ankur Mursalin
 *
 * https://encryptioner.github.io/
 *
 * Created on Mon Jul 25 2022
 */

<template>
  <div
    class="relative pb-8"
    :class="{
      [themeNo === 1 ? 'has-error-1' : 'has-error-2']: !!errorMessage || errorText,
      [themeNo === 1 ? 'success-1' : 'success-2']: meta.valid && !errorText,
    }"
  >
    <label
      :for="name"
      class="label block pt-0"
    >
      {{ label }}
      <span
        v-if="required"
        class="text-red-500"
      >*</span>
    </label>
    <div class="flex">
      <textarea
        :id="name"
        :name="name"
        :value="inputValue"
        :rows="rows"
        :placeholder="placeholder"
        :class="[
          themeNo === 1 ? 'input-1' : 'input-2',
          { 'resize-none': !resizable },
        ]"
        @input="onInput"
        @blur="onBlur"
        @change="onChange"
      />
    </div>

    <p
      class="help-message absolute p-1 text-sm"
    >
      <template
        v-if="errorMessage || errorText"
      >
        {{ errorMessage || errorText }}
      </template>
      <template
        v-else-if="meta.valid"
      >
        {{ successMessage }}
      </template>
    </p>
  </div>
</template>

<script lang="ts" setup>

import {
  useField,
} from 'vee-validate';
import {
  PropType,
  toRef,
  watch,
} from 'vue';

const emits = defineEmits<{
  (e: 'input', value: string): void;
  (e: 'blur', value: string): void;
  (e: 'change', value: string): void;
}>();

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    required: true,
  },
  rows: {
    type: Number,
    default: 4,
  },
  value: {
    type: String,
    default: '',
  },
  successMessage: {
    type: String,
    default: '',
  },
  placeholder: {
    type: String,
    default: '',
  },
  errorText: {
    type: String,
    default: '',
  },
  resizable: {
    type: Boolean,
    default: false,
  },
  required: {
    type: Boolean,
    default: false,
  },
  themeNo: {
    type: Number as PropType<1 | 2>,
    default: 2,
    validator: (type: number) => type === 1 || type === 2,
  },
});

const name = toRef(props, 'name');

// we don't provide any rules here because we are using form-level validation
// https://vee-validate.logaretm.com/v4/guide/validation#form-level-validation
const {
  value: inputValue,
  resetField,
  setState,
  errorMessage,
  handleBlur,
  handleChange,
  meta,
} = useField(name, undefined, {
  initialValue: props.value,
});

watch(() => props.value, () => {
  resetField({
    value: props.value,
  });
});

function onInput(e): void {
  emits('input', inputValue.value);
  handleChange(e);
}

function onBlur(e): void {
  setState({
    value: e.target.value.trim(),
  });
  emits('blur', inputValue.value);
  handleBlur(e);
}

function onChange(): void {
  emits('change', inputValue.value);
}

</script>
