<template>
  <div class="app relative z-[2147483647]">
    <ChatIcon
      v-show="!isChatOpen"
      @interface:open="openInterface"
    />
    <ChatInterface
      v-if="isChatInterfaceLoaded"
      v-show="isChatOpen"
      @interface:close="isChatOpen = false"
    />
  </div>
</template>

<script setup lang="ts">
import {
  ref,
} from 'vue';
import ChatIcon from '@/views/ChatIcon.vue';
import ChatInterface from '@/views/ChatInterface.vue';

const isChatInterfaceLoaded = ref(false);
const isChatOpen = ref(false);

function openInterface(): void {
  isChatInterfaceLoaded.value = true;
  isChatOpen.value = true;
}

</script>
