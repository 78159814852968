import {
  defineStore,
} from 'pinia';
import {
  IConversation,
} from 'types';

export const useConversationHistoryStore = defineStore('conversationHistory', {
  state: () => ({
    conversationList: [] as IConversation[],
    currentPage: 1,
    isLoading: false,
    hasMore: true,
    totalData: 0,
    newChatCountAfterMount: 0,
    showHistoryList: false,
  }),
  persist: {
    storage: window.sessionStorage,
  },
});
