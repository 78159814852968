/*
 * Ankur Mursalin
 *
 * https://encryptioner.github.io/
 *
 * Created on Tue Sep 03 2024
 */

<template>
  <div
    class="flex h-20 items-center justify-between rounded-t-md bg-[#143151] px-4 py-2 text-white"
  >
    <div
      class="relative"
    >
      <button
        type="button"
        class="tooltip"
        data-tip="Customer Support"
        :disabled="isSupportDisabled"
        @click="utilStore.isLeftNavigationMenuOn = !utilStore.isLeftNavigationMenuOn"
      >
        <PhCornersIn
          v-if="isLeftNavigationMenuOn"
          class="size-8 drop-shadow-2xl transition-all hover:text-red-500"
        />
        <PhList
          v-else
          class="size-8 drop-shadow-2xl transition-all hover:text-[#52fa7c]"
        />
      </button>
      <SupportMenuList
        v-if="isLeftNavigationMenuOn"
      />
    </div>
    <div
      class="flex items-center"
    >
      <img
        src="@/assets/logo-dark.png"
        alt="Logo"
        class="size-14"
      />
      <h1
        class="text-xl font-semibold"
      >
        {{ conversationHistoryStore.showHistoryList ? 'Chat History' : 'Chat Assistant' }}
      </h1>
    </div>
    <button
      type="button"
      @click="emits('close')"
    >
      <PhMinus
        class="hover:text-red-500"
        :size="32"
      />
    </button>
  </div>
</template>

<script lang="ts" setup>

import {
  PhCornersIn,
  PhList,
  PhMinus,
} from '@phosphor-icons/vue';
import {
  computed,
} from 'vue';
import SupportMenuList from './SupportMenuList.vue';
import {
  useStore,
} from '@/store';

const emits = defineEmits<{
  (e: 'close'): void;
}>();

const utilStore = useStore.util;
const isLeftNavigationMenuOn = computed(() => utilStore.isLeftNavigationMenuOn);

const isSupportDisabled = computed(() => !useStore.auth.userId || !useStore.auth.company);

const conversationHistoryStore = useStore.conversationHistory;

</script>
