/*
* Arafat hossain
*
* arafat@nerddevs.com
*
* Created on September 26, 2023
*/

<template>
  <div>
    <slot name="top" />
    <div ref="sentinel" />
    <slot name="bottom" />
  </div>
</template>

<script lang="ts" setup>
import {
  onMounted,
  onUnmounted,
  ref,
} from 'vue';

const sentinel = ref<HTMLDivElement | null>(null);
let observer: IntersectionObserver | null = null;

const emits = defineEmits<{
  (e: 'load'): void;
}>();

function handleIntersect(entries: IntersectionObserverEntry[]): void {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      emits('load');
    }
  });
}

onMounted(() => {
  observer = new IntersectionObserver(handleIntersect, {
    root: null,
    rootMargin: '0px',
    threshold: 0.5,
  });

  if (sentinel.value) {
    observer.observe(sentinel.value);
  }
});

onUnmounted(() => {
  if (sentinel.value && observer) {
    observer.unobserve(sentinel.value);
  }
});
</script>
