/*
 * Ankur Mursalin
 *
 * https://encryptioner.github.io/
 *
 * Created on Thu Aug 04 2022
 */

import {
  useAuthStore,
} from '@/store/auth';
import {
  useConversationHistoryStore,
} from '@/store/conversationHistory';
import {
  useConversationThreadStore,
} from '@/store/conversationThread';
import {
  useUtilStore,
} from '@/store/util';

export interface IAppUseStore {
  auth: ReturnType<typeof useAuthStore>;
  util: ReturnType<typeof useUtilStore>;
  conversationThread: ReturnType<typeof useConversationThreadStore>;
  conversationHistory: ReturnType<typeof useConversationHistoryStore>;
}

const useStore = {
} as IAppUseStore;

const registerStore = (): void => {
  useStore.auth = useAuthStore();
  useStore.util = useUtilStore();
  useStore.conversationThread = useConversationThreadStore();
  useStore.conversationHistory = useConversationHistoryStore();
};

const resetUserSpecificStore = (): void => {
  useAuthStore().$reset();
  useConversationThreadStore().$reset();
  useConversationHistoryStore().$reset();
};

const resetStore = (): void => {
  resetUserSpecificStore();
  useUtilStore().$reset();
};

export {
  resetUserSpecificStore,
  registerStore,
  resetStore,
  useStore,
};

// NOTE: For hot module replacement see https://pinia.vuejs.org/cookbook/hot-module-replacement.html
