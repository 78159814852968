<template>
  <div
    class="absolute -right-32 top-15 z-50 rounded-lg bg-gray-100 p-4 text-black shadow-lg"
  >
    <ul>
      <li
        v-for="(support, index) in finalSupportMenuList"
        :key="index"
      >
        <button
          type="button"
          class="mt-2 flex w-36 items-center gap-4 transition-colors hover:text-blue-700"
          @click="handleContact(support.id)"
        >
          <component
            :is="support.icon"
            class="size-5 text-black"
          />
          {{ support.text }}
        </button>
      </li>
    </ul>
  </div>
</template>

<script lang="ts" setup>
import {
  computed,
} from 'vue';
import {
  supportMenuList,
} from '@/helpers';
import {
  useStore,
} from '@/store';

const authStore = useStore.auth;
const utilStore = useStore.util;
const conversationThreadStore = useStore.conversationThread;
const conversationHistoryStore = useStore.conversationHistory;

const phoneNo = computed(() => !!authStore.company?.contactSettings?.phoneNo);
const finalSupportMenuList = computed(() => supportMenuList.filter((item) => !(item.id === 1 && !phoneNo.value)));

function handleContact(supportId: number): void {
  utilStore.isSupportOn = true;
  utilStore.supportId = supportId;
  utilStore.isLeftNavigationMenuOn = false;
  conversationHistoryStore.showHistoryList = false;

  conversationThreadStore.messages = [];

  if (supportId !== 3) {
    conversationThreadStore.uuid = '';
  }
}

</script>
