/*
 * Ankur Mursalin
 *
 * https://encryptioner.github.io/
 *
 * Created on Tue Sep 03 2024
 */

<template>
  <div
    class="sticky top-0 z-10 flex w-full justify-between"
  >
    <button
      type="button"
      class="history-list-btn"
      @click="handleShowHistoryList"
    >
      <PhSidebar
        :size="28"
        class="opacity-75"
      />
    </button>
    <button
      v-if="conversationThreadStore.uuid || utilStore.isSupportOn"
      type="button"
      class="create-new-btn"
      @click="emits('new-chat')"
    >
      <PhNotePencil
        :size="28"
        class="opacity-75"
      />
    </button>
  </div>
</template>

<script lang="ts" setup>

import {
  PhNotePencil,
  PhSidebar,
} from '@phosphor-icons/vue';
import {
  useStore,
} from '@/store';

const emits = defineEmits<{
  (e: 'new-chat'): void;
}>();

const conversationHistoryStore = useStore.conversationHistory;
const conversationThreadStore = useStore.conversationThread;
const utilStore = useStore.util;

function handleShowHistoryList(): void {
  conversationHistoryStore.showHistoryList = true;
  utilStore.isSupportOn = false;
  utilStore.supportId = -1;
}

</script>

<style scoped lang="scss">

.history-list-btn, .create-new-btn {
  @apply m-2 cursor-pointer rounded-sm bg-gray-100
  p-1 shadow-md hover:bg-gray-300;
}

</style>
