<template>
  <div
    class="flex h-auto min-h-14 items-center justify-between border-y p-2"
  >
    <div
      class="flex w-full items-center rounded-xl px-2"
    >
      <textarea
        ref="newMessageRef"
        v-model="newMessage"
        placeholder="Have a question? Ask now!"
        rows="1"
        class="h-auto w-full resize-none overflow-y-hidden border-none
            bg-transparent text-base leading-6 outline-none md:px-0"
        @input="adjustInputSize"
        @blur="resetInputSize"
        @keyup.enter="sendMessage"
      />
    </div>
    <button
      type="button"
      :class="{
        'btn-disabled': isSendMessageDisabled,
      }"
      :disabled="isSendMessageDisabled"
      @click="sendMessage"
    >
      <img
        v-if="conversationThreadStore.isReplyLoading"
        src="@/assets/Chat-Loading.gif"
        alt="chat-loading"
        class="h-7 w-14"
      >
      <PhPaperPlaneRight
        v-else
        class="size-6"
        weight="fill"
        :class="{
          'fill-gray-500': isSendMessageDisabled,
          'fill-blue-500 hover:fill-blue-600': !isSendMessageDisabled,
        }"
      />
    </button>
  </div>
</template>

<script lang="ts" setup>
import {
  PhPaperPlaneRight,
} from '@phosphor-icons/vue';
import {
  computed,
  onMounted,
  ref,
  watch,
} from 'vue';
import {
  type ConversationSocketService,
  getConversationSocketService,
} from '@/services/ConversationSocketService';
import {
  useStore,
} from '@/store';

const props = defineProps({
  query: {
    type: String,
    default: '',
  },
});

const newMessage = ref('');

watch(
  () => props.query,
  () => {
    newMessage.value = props.query;
    newMessageRef.value?.focus();
  },
);

const newMessageRef = ref<HTMLTextAreaElement>();

let conversationSocketService: ConversationSocketService;

const conversationThreadStore = useStore.conversationThread;

const isSendMessageDisabled = computed(() => !newMessage.value.trim().length);

function sendMessage(): void {
  if (conversationThreadStore.isReplyLoading) {
    return;
  }

  if (newMessage.value) {
    conversationSocketService.sendMessage(newMessage.value.trim());
    newMessage.value = '';
  }

  if (newMessageRef.value) {
    newMessageRef.value.style.height = 'auto';
  }
}

const maxLine = 6;

function adjustInputSize(): void {
  if (!newMessageRef.value) {
    return;
  }

  newMessageRef.value.style.height = 'auto';
  newMessageRef.value.style.height = `${newMessageRef.value.scrollHeight}px`;

  const fontSize = parseFloat(getComputedStyle(newMessageRef.value).fontSize);
  const maxHeight = maxLine * 1.5 * fontSize;

  if (newMessageRef.value.scrollHeight > maxHeight) {
    newMessageRef.value.style.overflowY = 'auto';
    // Since we're using text-base, the conversion is 1.5x to px.
    newMessageRef.value.style.maxHeight = `${maxHeight}px`; // Adjust as needed
  } else {
    newMessageRef.value.style.overflowY = 'hidden';
    newMessageRef.value.style.maxHeight = 'none';
  }
}

function resetInputSize(): void {
  if (!newMessageRef.value) {
    return;
  }
  const textarea = newMessageRef.value;
  textarea.style.height = `${newMessageRef.value.scrollHeight}px`;
  textarea.style.overflowY = 'auto';
}

onMounted(() => {
  conversationSocketService = getConversationSocketService();
});

</script>

<style scoped lang="scss">

/* width */
::-webkit-scrollbar {
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

</style>
