import axios, {
  AxiosError,
} from 'axios';
import {
  createPinia,
} from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import {
  App as VueApp,
  createApp,
} from 'vue';
import App from '@/App.vue';
import {
  IS_DEVELOPMENT,
  ROUTE_API,
  baseUrl,
} from '@/helpers';
import {
  registerStore,
  resetStore,
  resetUserSpecificStore,
} from '@/store';
import '@/style/css/index.css';
import '@/style/scss/index.scss';

function createMyApp(): VueApp {
  const app = createApp(App);
  const pinia = createPinia();

  pinia.use(piniaPluginPersistedstate);
  app.use(pinia);

  app.config.errorHandler = (err, vm, info): void => {
    console.error(`Error in ${info}:`, err);

    const username = 'NA';

    const data = {
      message: `[${username}] ${(err as Error).message}`,
      info,
      stack: (err as Error).stack,
    };
    axios.post('/errors', data)
      .catch(console.error);
  };

  axios.defaults.baseURL = `${baseUrl}${ROUTE_API}`;

  axios.interceptors.response.use(undefined, async (error) => {
    const errResponse = (error as AxiosError<{message: string}>).response;

    if (errResponse?.status === 401) {
      resetUserSpecificStore();
    }

    return Promise.reject(error);
  });

  resetStore();
  registerStore();

  return app;
}

if (IS_DEVELOPMENT) {
  createMyApp().mount('#app');
} else {
  window.chatApp = createMyApp();
}
