<template>
  <div class="relative">
    <button
      type="button"
      class="fixed bottom-0 right-5 z-10 mb-5 rounded-3xl bg-[#f1f1f1] p-2 shadow-lg
      hover:bg-slate-300 md:right-20"
      @click="emits('interface:open')"
    >
      <img
        src="@/assets/interface-start-icon.gif"
        alt="chat"
        class="size-18"
      />
    </button>
  </div>
</template>

<script setup lang="ts">

const emits = defineEmits<{
  (e: 'interface:open'): void;
}>();

</script>
