import {
  PhEnvelopeSimple,
  PhPhoneCall,
  PhPhoneOutgoing,
} from '@phosphor-icons/vue';

export const CONSTANTS = {
  LOGS: {
    INFO: 'log-info',
  } as const,
  STORAGE: {
    USER_ID: 'ai123456890u',
    TOKEN: 'ai012345698t',
  } as const,
} as const;

export const supportMenuList = [
  {
    id: 1,
    icon: PhPhoneCall,
    text: 'Call Us',
  },
  {
    id: 2,
    icon: PhEnvelopeSimple,
    text: 'Message Us',
  },
  {
    id: 3,
    icon: PhPhoneOutgoing,
    text: 'Let Us Contact',
  }];
