/*
 * Ankur Mursalin
 *
 * https://encryptioner.github.io/
 *
 * Created on Tue Oct 03 2023
 */

import axios from 'axios';
import {
  handleAxiosError,
} from '@/helpers';
import {
  IAppUseStore,
  useStore,
} from '@/store';
import {
  IConversation,
  IConversationMessage,
  IGetConversationsData,
} from '@/type';

interface IGetPreviousConversationsParams {
  conversationId: string;
  currentPage: number;
  limit: number;
  createdBefore: string;
}

type IPreviousConversationData = IGetConversationsData;

// declared on getConversationThreadService
let conversationThreadStore: IAppUseStore['conversationThread'];

let conversationThreadService: ConversationThreadService | undefined;

const INITIAL_HISTORY_LIMIT = 10;

export class ConversationThreadService {
  userId: string;

  constructor() {
    this.userId = useStore.auth.userId as string;
  }

  private async _getPreviousConversations({
    conversationId,
    currentPage,
    limit,
    createdBefore,
  }: IGetPreviousConversationsParams): Promise<IPreviousConversationData> {
    let previousConversationData: IPreviousConversationData = {
      totalData: 0,
      conversations: [],
    };

    const fields = [
      '_id',
      'conversationId',
      'query',
      'completionText',
      'createdAt',
    ].join(',');

    const url = `/conversations/user/${this.userId}?pageSize=${
      limit
    }&pageNo=${
      currentPage
    }&conversationId=${
      conversationId
    }&createdBefore=${
      createdBefore
    }&fields=${
      fields
    }`;

    const isInitialPage = currentPage === 1;

    try {
      const response = await axios.get<IGetConversationsData>(url);

      const { totalData, conversations } = response.data;

      previousConversationData = {
        ...previousConversationData,
        totalData,
        conversations,
      };

      if (!conversations.length || !isInitialPage) {
        return previousConversationData;
      }
    } catch (e: any) {
      handleAxiosError(e);
    }

    return previousConversationData;
  }

  _addPreviousConversations(conversations: IConversation[]): void {
    const { messages } = conversationThreadStore;

    for (const conversation of conversations) {
      const reply: IConversationMessage = {
        id: conversation._id,
        content: conversation.completionText || '',
        type: 'receiver',
        hasPreview: false,
      };

      const query: IConversationMessage = {
        id: conversation._id,
        content: conversation.query || '',
        type: 'sender',
        hasPreview: false,
      };

      messages.unshift(reply);
      messages.unshift(query);
    }

    conversationThreadStore.messages = [...messages];
  }

  async loadPreviousConversations(
    forPage: number,
    createdBefore: string,
  ): Promise<{loadCount: number}> {
    if (forPage === 1) {
      conversationThreadStore.totalPage = 0;
    }

    const { isHistoryLoading, uuid, totalPage } = conversationThreadStore;

    if (isHistoryLoading) {
      return {
        loadCount: 0,
      };
    }

    if (totalPage > 0 && forPage > totalPage) {
      return {
        loadCount: 0,
      };
    }

    conversationThreadStore.isHistoryLoading = true;

    const previousConversationData = await this._getPreviousConversations(
      {
        conversationId: uuid,
        currentPage: forPage,
        limit: INITIAL_HISTORY_LIMIT,
        createdBefore,
      },
    );

    const {
      totalData,
      conversations,
    } = previousConversationData;

    if (!conversations.length) {
      conversationThreadStore.isHistoryLoading = false;
      return {
        loadCount: 0,
      };
    }

    if (forPage === 1) {
      conversationThreadStore.totalPage = Math.ceil(totalData / INITIAL_HISTORY_LIMIT);
    }

    this._addPreviousConversations(conversations);

    conversationThreadStore.isHistoryLoading = false;

    return {
      loadCount: conversations.length,
    };
  }

  clearHistoryAssociatedFields(): void {
    conversationThreadStore.templateId = '';

    conversationThreadStore.streamingConversation = undefined;
    conversationThreadStore.isReplyLoading = false;

    conversationThreadStore.totalPage = 0;

    conversationThreadStore.messages.splice(0, conversationThreadStore.messages.length);
  }
}

// NOTE: make sure getConversationThreadService is called after vue mounted
export function getConversationThreadService(): ConversationThreadService {
  if (conversationThreadService) {
    return conversationThreadService;
  }

  conversationThreadStore = useStore.conversationThread;

  conversationThreadService = new ConversationThreadService();

  return conversationThreadService;
}

export function clearConversationThreadService(): void {
  if (!conversationThreadService) {
    return;
  }

  conversationThreadService = undefined;
}
