import {
  AxiosError,
} from 'axios';
import {
  getAppInfo,
} from 'utilities';
import {
  baseServerUrl,
} from '@/configs/client.config.json';
import {
  IAppUseStore,
  useStore,
} from '@/store';

export * from './constants';

export const {
  SERVER_PORT,
  CLIENT_PORT,
  IS_DEVELOPMENT,
  CONFIG,
  ROUTE_API,
  VIEW_API,
} = getAppInfo();

const { protocol, hostname } = window.location;
let conversationThreadStore: IAppUseStore['conversationThread'];

// for local testing, baseServerUrl = https://localhost:3001 can be set, if necessary
export const baseUrl = baseServerUrl || (IS_DEVELOPMENT
  ? `${protocol}//${hostname}:${SERVER_PORT}`
  : window.location.origin);

export const clientBaseUrl = IS_DEVELOPMENT
  ? `${protocol}//${hostname}:${CLIENT_PORT}`
  : window.location.origin;

export function isYouTubeUrl(url: string): boolean {
  return /https?:\/\/(www\.)?youtu\.?be/.test(url);
}

export const isUUIDv4 = (inputString: string): boolean => {
  const uuidv4Pattern = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-4[0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/;
  return uuidv4Pattern.test(inputString);
};

export const sleep = (ms: number): Promise<unknown> => new Promise((r) => {
  setTimeout(r, ms);
});

export function ordinalize(n: number): string {
  const s = ['th', 'st', 'nd', 'rd'];
  const v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
}

export function viewportBreakpoint(): string {
  const width = window.innerWidth;
  let breakpoint = 'xs';

  if (width >= 640) {
    breakpoint = 'sm';
  }
  if (width >= 768) {
    breakpoint = 'md';
  }
  if (width >= 1024) {
    breakpoint = 'lg';
  }
  if (width >= 1280) {
    breakpoint = 'xl';
  }
  if (width >= 1536) {
    breakpoint = '2xl';
  }
  return breakpoint;
}

export function durationTime(val: number): string {
  if (!val) {
    return '';
  }
  return val >= 3600
    ? new Date(val * 1000).toISOString().substr(11, 8)
    : new Date(val * 1000).toISOString().substr(14, 5);
}

export function handleAxiosError<T extends {
  message: string;
  errors?: Map<string, string> | Record<string, string | undefined>;
}>(e: AxiosError<T>, defaultMessage = 'Sorry, there is a problem. Please try later', showAdditionalError = false): void {
  console.error(e);

  conversationThreadStore = useStore.conversationThread;

  if (!e.isAxiosError || !e?.response?.data) {
    conversationThreadStore.notification = {
      message: defaultMessage,
      type: 'error',
    };
    return;
  }

  let message = '';
  let errorMessages = '';

  if (typeof e.response.data === 'string') {
    message = e.response.data;
  } else if (e.response.data.message) {
    message = e.response.data.message;
  } else {
    message = defaultMessage || e.response.statusText;
  }

  if (e.response.data.errors && showAdditionalError) {
    const { errors } = e.response.data;
    for (const key of Object.keys(errors)) {
      errorMessages += `, ${key}: `;
      if (errors instanceof Map) {
        errorMessages += errors.get(key);
      } else {
        errorMessages += errors[key];
      }
    }
  }

  const finalMessage = message + errorMessages;
  conversationThreadStore.notification = {
    message: finalMessage,
    type: 'error',
  };
}

export async function copyToClipboard(text: string): Promise<void> {
  const _legacyCopy = (): void => {
    const input: HTMLInputElement = window.document.createElement('input');
    input.value = text;
    document.body.appendChild(input);
    input.focus();
    input.select();

    document.execCommand('copy');
    document.body.removeChild(input);
  };

  try {
    await navigator.clipboard.writeText(text);
  } catch (err) {
    _legacyCopy();
  }
}
