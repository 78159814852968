<template>
  <div
    class="fixed bottom-5 right-5 z-10 flex h-[550px] w-10/12 max-w-md flex-col
    rounded-lg border bg-white text-sm shadow-lg md:w-full md:text-base"
  >
    <div
      class="relative"
    >
      <FullContentButton
        v-if="isLeftNavigationMenuOn"
        class="z-10"
        @click="utilStore.isLeftNavigationMenuOn = !utilStore.isLeftNavigationMenuOn"
      />
      <ChatTitle
        @close="emits('interface:close')"
      />

      <PropNotification
        v-if="notification"
        :message="notification.message"
        :type="notification.type"
        :permanent="notification.permanent"
        @close="conversationThreadStore.notification = undefined"
      />

      <div
        v-if="authStore.userId"
        :class="{ 'opacity-50': isLeftNavigationMenuOn }"
      >
        <!-- Chat history list ui start-->
        <div
          v-show="conversationHistoryStore.showHistoryList"
          class="my-2 h-full"
        >
          <div
            class="my-2 flex justify-center text-[#6B6B6B]"
          >
            <button
              type="button"
              class="btn flex gap-2 rounded-lg border-[#143151] bg-[#F8F8F8]
            px-4 py-2 shadow-md hover:bg-green-500"
              @click="onClickNewConversation"
            >
              <PhNotePencil :size="32" />
              <p>New Conversation</p>
            </button>
          </div>
          <ChatHistoryList
            :class="[
              conversationThreadStore.notification ? 'h-86' : 'h-98',
              { 'overflow-y-scroll': conversationHistoryStore.conversationList.length > 2 },
            ]"
            @conversation:id="onSingleHistoryClick($event)"
          />

          <CustomLoader1
            v-if="conversationHistoryStore.isLoading"
          />
        </div>
        <!-- Chat history list ui end -->

        <!-- Chat thread ui start -->
        <div
          v-show="!conversationHistoryStore.showHistoryList"
          class="relative grow"
        >
          <CustomLoader1
            v-if="conversationHistoryStore.isLoading"
            class="flex h-full"
          />
          <template
            v-else
          >
            <ChatNavigation
              @new-chat="onClickNewConversation"
            />
            <!-- conversation message body start -->
            <div
              v-if="!isSupportOn"
              ref="conversationDiv"
              class="relative"
              :class="[
                conversationThreadStore.notification ? 'h-78' : 'h-90',
                { 'overflow-y-scroll': hasThreadMsg },
              ]"
            >
              <CustomLoader1
                v-if="conversationThreadStore.isHistoryLoading"
                class="flex h-full"
              />
              <div
                v-else-if="!hasThreadMsg"
              >
                <InstructionMsgs />
                <InstructionActions
                  v-if="!chatQuery"
                />
              </div>

              <!-- conversation thread start -->
              <div
                v-if="hasThreadMsg"
                class="relative h-full"
              >
                <div
                  class="mt-3"
                >
                  <InfiniteScroll
                    @load="loadConversations"
                  >
                    <template
                      #bottom
                    >
                      <MessageList />
                    </template>
                  </InfiniteScroll>
                </div>
              </div>
              <!-- conversation thread end -->
            </div>
            <div
              v-else
              class="h-[416px] overflow-y-scroll"
            >
              <ContactSupport
                v-if="supportId === 3"
              />
              <CallOrMessageSupport
                v-else
              />
            </div>
            <!-- conversation message body end -->
            <!-- Chat input start -->
            <div
              v-if="!isSupportOn"
              class="absolute -bottom-5 w-full bg-white"
            >
              <ChatInput
                class="px-4"
                :query="chatQuery"
              />
            </div>
            <!-- Chat input end -->
            <ChatFooter
              class="absolute -bottom-14 mb-1 w-full"
            />
          </template>
        </div>
        <!-- Chat thread ui end -->
      </div>
      <CustomLoader1
        v-else-if="authStore.loading"
        class="my-auto"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>

import {
  PhNotePencil,
} from '@phosphor-icons/vue';
import {
  computed,
  onMounted,
  ref,
  watch,
} from 'vue';
import PropNotification from '@/components/App/PropNotification.vue';
import CallOrMessageSupport from '@/components/ChatInterface/CallOrMessageSupport.vue';
import ChatFooter from '@/components/ChatInterface/ChatFooter.vue';
import ChatHistoryList from '@/components/ChatInterface/ChatHistoryList.vue';
import ChatInput from '@/components/ChatInterface/ChatInput.vue';
import ChatNavigation from '@/components/ChatInterface/ChatNavigation.vue';
import ChatTitle from '@/components/ChatInterface/ChatTitle.vue';
import ContactSupport from '@/components/ChatInterface/ContactSupport.vue';
import InstructionActions from '@/components/ChatInterface/InstructionActions.vue';
import InstructionMsgs from '@/components/ChatInterface/InstructionMsgs.vue';
import MessageList from '@/components/ChatInterface/MessageList.vue';
import FullContentButton from '@/components/Helper/FullContentButton.vue';
import InfiniteScroll from '@/components/Helper/InfiniteScroll.vue';
import CustomLoader1 from '@/components/Helper/Loader/CustomLoader1.vue';
import {
  getConversationSocketService,
} from '@/services/ConversationSocketService';
import {
  type ConversationThreadService,
  getConversationThreadService,
} from '@/services/ConversationThreadService';
import {
  useStore,
} from '@/store';

const emits = defineEmits<{
  (e: 'interface:close'): void;
}>();

type ScrollBehavior = 'auto' | 'instant' | 'smooth';

const chatQuery = ref('');

const conversationDiv = ref<HTMLDivElement | null>(null);

let currentConversationPage = 0;
let uuidChangedAt = new Date().toISOString();
let isHistory = false;

const authStore = useStore.auth;
const utilStore = useStore.util;
const conversationThreadStore = useStore.conversationThread;
const conversationHistoryStore = useStore.conversationHistory;

let conversationThreadService: ConversationThreadService;

const hasThreadMsg = computed(() => !!conversationThreadStore.messages.length);
const notification = computed(() => conversationThreadStore.notification);
const isLeftNavigationMenuOn = computed(() => utilStore.isLeftNavigationMenuOn);
const isSupportOn = computed(() => utilStore.isSupportOn);
const supportId = computed(() => utilStore.supportId);

async function loadConversations(): Promise<void> {
  if (!isHistory || !conversationThreadStore.uuid) {
    return;
  }
  const earlierConversationScrollHeight = conversationDiv.value?.scrollHeight || 0;

  const { loadCount } = await conversationThreadService
    .loadPreviousConversations(currentConversationPage + 1, uuidChangedAt);

  if (loadCount) {
    const totalConversationScrollHeight = conversationDiv.value?.scrollHeight || 0;

    // go close to earlier position
    _scrollConversationMessage('instant', totalConversationScrollHeight - earlierConversationScrollHeight);
    currentConversationPage += 1;
  }
}

async function onSingleHistoryClick(
  conversationId: string,
): Promise<void> {
  conversationHistoryStore.showHistoryList = false;
  chatQuery.value = '';

  if (conversationId === conversationThreadStore.uuid) {
    return;
  }

  conversationThreadStore.messages = [];

  // history chat
  conversationThreadStore.uuid = conversationId;
  isHistory = true;

  uuidChangedAt = new Date().toISOString();

  const { loadCount } = await conversationThreadService.loadPreviousConversations(1, uuidChangedAt);

  if (loadCount) {
    _scrollConversationMessage('instant');

    currentConversationPage += 1;
  }
}

function _scrollConversationMessage(
  behavior: ScrollBehavior,
  top = conversationDiv.value?.scrollHeight,
): void {
  conversationDiv.value?.scrollTo({
    behavior,
    top,
  });
}

function onClickNewConversation(): void {
  utilStore.isSupportOn = false;
  utilStore.supportId = -1;

  conversationHistoryStore.showHistoryList = false;
  utilStore.isSupportOn = false;
  conversationThreadStore.messages = [];
  conversationThreadStore.uuid = '';
  chatQuery.value = '';
}

onMounted(async () => {
  const userId = await useStore.auth.getUser();

  if (!userId) {
    return;
  }

  getConversationSocketService();

  conversationThreadService = getConversationThreadService();
});

watch(() => conversationThreadStore.messages, () => {
  if (!conversationThreadStore.isReplyLoading || !conversationThreadStore.messages.length) {
    return;
  }

  // reply is being added with conversation message, go to bottom
  _scrollConversationMessage('instant');
});

watch(() => conversationThreadStore.isReplyLoading, (x, y) => {
  // if reply loading starts or ends, go to bottom of conversation
  setTimeout(() => {
    _scrollConversationMessage('instant');
  }, 1000 * 0.25);
});

</script>

<style scoped lang="scss">

/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

</style>
