/*
 * Ankur Mursalin
 *
 * https://encryptioner.github.io/
 *
 * Created on Wed Nov 01 2023
 */
import axios, {
  AxiosError,
} from 'axios';
import type {
  CreateConversationDto,
  ResumeConversationDto,
} from 'dto';
import {
  Socket,
  io,
} from 'socket.io-client';
import {
  SocketClientEvent,
  SocketServerEvent,
} from 'utilities';
import {
  baseUrl,
  handleAxiosError,
} from '@/helpers';
import {
  IAppUseStore,
  useStore,
} from '@/store';
import {
  IBaseSocketPayload,
  IConversation,
  IConversationMessage,
} from '@/type';

interface IAddConversationReplyParam {
  id: string;
  content: string;
  hasPreview: boolean;
}

interface IConversationResponseData {
  status: number;
  conversation?: IConversation;
  message?: string;
}

// declared on getConversationSocketService
let conversationThreadStore: IAppUseStore['conversationThread'];
let conversationHistoryStore: IAppUseStore['conversationHistory'];

let conversationSocketService: ConversationSocketService | undefined;

export class ConversationSocketService {
  socket: Socket;

  userId: string;

  token: string;

  isEventListenerAdded: boolean;

  interval: ReturnType<typeof setTimeout> | undefined;

  constructor() {
    this.socket = io(baseUrl);
    this.isEventListenerAdded = false;

    this.userId = useStore.auth.userId as string;
    this.token = useStore.auth.token;
  }

  private _addReply({ id, content, hasPreview }: IAddConversationReplyParam): void {
    const message: IConversationMessage = {
      id,
      content,
      type: 'receiver',
      hasPreview,
    };

    const { messages } = conversationThreadStore;

    const lastIndex = messages.length - 1;

    const isPreviousMessageFromSender = messages[lastIndex].type === 'sender';

    if (isPreviousMessageFromSender) {
      // update id of sender message
      messages[lastIndex].id = id;
      messages.push(message);
      conversationThreadStore.messages = [...messages];
      return;
    }

    // add new content to existing reply
    message.content = messages[lastIndex].content + content;
    messages[lastIndex] = message;
    conversationThreadStore.messages = [...messages];
  }

  private async _getReply(
    url: string,
    payload: CreateConversationDto | ResumeConversationDto,
  ): Promise<IConversationResponseData> {
    const responseData: IConversationResponseData = {
      status: 0,
    };

    try {
      const response = await axios.post<{conversation: IConversation}>(
        url,
        payload,
      );

      responseData.conversation = response.data.conversation;
      responseData.status = response.status;
      return responseData;
    } catch (e: any) {
      if (!e?.isAxiosError) {
        handleAxiosError(e);
        return responseData;
      }

      const axiosError = e as AxiosError<{message: string}>;
      responseData.message = axiosError.response?.data.message;
      return responseData;
    }
  }

  private _setIsReplyLoading(isReplyLoading: boolean): void {
    conversationThreadStore.isReplyLoading = isReplyLoading;
    conversationThreadStore.streamingConversation = undefined;

    if (this.interval) {
      clearInterval(this.interval);
      this.interval = undefined;
    }
    // If the response takes 60 seconds, we'll show a warning.
    if (isReplyLoading) {
      this.interval = setInterval(() => {
        conversationThreadStore.notification = {
          message: 'Response is taking longer than usual,\nplease wait or reload the page',
          type: 'warning',
        };
      }, 60 * 1000);
    }
  }

  private async _setReply(
    url: string,
    payload,
  ): Promise<void> {
    const responseData = await this._getReply(url, payload);

    const { status } = responseData;

    const errorReply: IAddConversationReplyParam = {
      id: '',
      content: 'Oops! Something went wrong! Please try again.',
      hasPreview: false,
    };

    if (status === 0) {
      this._addReply(errorReply);
      this._setIsReplyLoading(false);
      return;
    }

    const { conversation } = responseData;

    if (conversation) {
      conversationThreadStore.streamingConversation = conversation;
      return;
    }

    const { message } = responseData;

    if (!message) {
      this._addReply(errorReply);
      this._setIsReplyLoading(false);
      return;
    }

    conversationThreadStore.notification = {
      message,
      type: 'error',
    };

    errorReply.content = `Oops! Something went wrong!\n\n${message}`;
    this._addReply(errorReply);
    this._setIsReplyLoading(false);
  }

  private _onSocketStream(): void {
    this.socket.on(SocketServerEvent.STREAM_CONVERSATION, (data) => {
      const {
        isReplyLoading,
        streamingConversation,
        uuid,
      } = conversationThreadStore;

      if (!isReplyLoading) {
        return;
      }

      if (!streamingConversation) {
        this._setIsReplyLoading(false);
        return;
      }

      const {
        _id: currentId,
        query,
      } = streamingConversation;

      const {
        _id,
        success,
        message,
        index,
        hasEnded,
        conversationId,
      } = data;

      if (currentId !== _id) {
        return;
      }

      if (uuid && uuid !== conversationId) {
        return;
      }

      if (success) {
        this._addReply({
          id: _id,
          content: message,
          hasPreview: false,
        });
      } else {
        const prefix = index === 0 ? '' : '\n\n';
        const content = `${prefix}Oops! Something went wrong!\n\n${message}`;

        conversationThreadStore.notification = {
          message,
          type: 'error',
        };

        this._addReply({
          id: _id,
          content,
          hasPreview: false,
        });
      }

      if (hasEnded) {
        this._setIsReplyLoading(false);
      }

      if (!uuid && hasEnded) {
        conversationThreadStore.uuid = conversationId;
        const { messages } = conversationThreadStore;

        const newChat: Partial<IConversation> = {
          _id: conversationId,
          conversationId,
          completionText: messages[1].content,
          query,
          createdAt: new Date().toISOString(),
        };

        conversationHistoryStore.conversationList.push(newChat as IConversation);
        conversationHistoryStore.newChatCountAfterMount += 1;
      }
    });
  }

  private _sendJoinEvent(): void {
    const { userId, token } = useStore.auth;

    if (!userId) {
      return;
    }

    const payload: IBaseSocketPayload = {
      token,
      userId,
    };

    this.socket.emit(SocketClientEvent.JOIN, payload);
  }

  private _onSocketConnect(): void {
    this.socket.on('connect', () => {
      console.log(`_onSocketConnect -> socketId = ${this.socket.id}`);

      this._sendJoinEvent();

      conversationThreadStore.isSocketConnected = this.socket.connected;
    });
  }

  private _onSocketDisconnect(): void {
    this.socket.on('disconnect', () => {
      conversationThreadStore.isSocketConnected = this.socket.connected;
      console.log(`_onSocketDisconnect -> socketId = ${this.socket.id}`);
    });
  }

  private _addSocketEventListener(): void {
    if (this.isEventListenerAdded) {
      return;
    }

    this._onSocketConnect();
    this._onSocketDisconnect();
    this._onSocketStream();

    this.isEventListenerAdded = true;
  }

  private _connectSocket(): void {
    this._addSocketEventListener();

    this.disconnectSocket();

    this.socket.connect();
  }

  disconnectSocket(): void {
    if (this.socket.connected) {
      this.socket.disconnect();
    }
  }

  async sendMessage(
    query: string,
  ): Promise<void> {
    const {
      isReplyLoading,
      isSocketConnected,
      messages,
      sentMessageCountAfterMount,
    } = conversationThreadStore;

    const { userId } = useStore.auth;

    if (isReplyLoading || !userId) {
      return;
    }

    const isFirstMessageAfterMount = sentMessageCountAfterMount === 0;

    if (isFirstMessageAfterMount) {
      conversationThreadStore.sentMessageCountAfterMount = 1;

      if (!isSocketConnected) {
        this._connectSocket();
      }
    }
    this._setIsReplyLoading(true);

    const message: IConversationMessage = {
      id: '',
      content: query?.trim() || '',
      type: 'sender',
      hasPreview: false,
    };

    messages.push(message);
    conversationThreadStore.messages = [...messages];

    const { uuid } = conversationThreadStore;

    const url = uuid ? '/conversations/resume' : '/conversations';

    const payload: CreateConversationDto | ResumeConversationDto = {
      userId,
      query,
      ...(uuid && {
        conversationId: uuid,
      }),
    };

    this._setReply(url, payload);

    if (!isFirstMessageAfterMount) {
      conversationThreadStore.sentMessageCountAfterMount += 1;
    }
  }
}

// NOTE: make sure getConversationSocketService is called after vue mounted
export function getConversationSocketService(): ConversationSocketService {
  if (conversationSocketService) {
    return conversationSocketService;
  }

  conversationThreadStore = useStore.conversationThread;
  conversationHistoryStore = useStore.conversationHistory;

  conversationSocketService = new ConversationSocketService();

  return conversationSocketService;
}

export function clearConversationSocketService(): void {
  if (!conversationSocketService) {
    return;
  }

  conversationSocketService.disconnectSocket();
  conversationSocketService = undefined;
}
