/*
 * Ankur Mursalin
 *
 * https://encryptioner.github.io/
 *
 * Created on Fri Jul 22 2022
 */

<template>
  <div
    class="relative pb-7"
    :class="{
      [themeNo === 1 ? 'has-error-1' : 'has-error-2']: !!errorMessage || errorText,
      [themeNo === 1 ? 'success-1' : 'success-2']: meta.valid && !errorText,
    }"
  >
    <div class="flex justify-between">
      <label
        class="label block pt-0"
        :for="name"
      >
        {{ label }}
        <span
          v-if="required"
          class="text-red-500"
        >*</span>
      </label>
      <PhPencilSlash
        v-if="disabled"
        class="mr-2"
      />
    </div>

    <div class="flex">
      <div
        v-if="flag"
        class="z-1 my-auto ml-px flex h-14 w-19 rounded-l-md bg-slate-200"
      >
        <div
          v-if="flag === 'BD'"
          class="m-auto"
        >
          <!-- REFERENCE: https://emojiterra.com/flag-for-bangladesh/ -->
          <span>🇧🇩 +88</span>
        </div>
      </div>
      <input
        :id="name"
        ref="inputEl"
        :name="name"
        :type="computedType"
        :value="inputValue"
        :placeholder="placeholder"
        :readonly="disabled"
        :disabled="disabled"
        :step="step"
        :autocomplete="autocomplete"
        :class="[
          themeNo === 1 ? 'input-1' : 'input-2',
          isPasswordType ? 'pr-10' : 'pr-3',
          { 'ml-[-78px] pl-22': flag },
          { 'pointer-events-none': disabled },
        ]"
        @keypress="onKeyPress"
        @input="onInput"
        @blur="onBlur"
        @change="onChange"
      >
      <template
        v-if="isPasswordType"
      >
        <EyeSlashIcon
          v-if="isPasswordHidden"
          class="-ml-8 mt-4 size-6 cursor-pointer"
          @click.prevent="isPasswordHidden = !isPasswordHidden"
        />
        <EyeIcon
          v-else
          class="-ml-8 mt-4 size-6 cursor-pointer"
          @click.prevent="isPasswordHidden = !isPasswordHidden"
        />
      </template>
    </div>
    <p
      class="help-message absolute p-1 text-sm"
    >
      <template
        v-if="errorMessage || errorText"
      >
        {{ errorMessage || errorText }}
      </template>
      <template
        v-else-if="meta.valid"
      >
        {{ successMessage }}
      </template>
    </p>
  </div>
</template>

<script lang="ts" setup>

import {
  EyeIcon,
  EyeSlashIcon,
} from '@heroicons/vue/24/outline';
import {
  PhPencilSlash,
} from '@phosphor-icons/vue';
import {
  useField,
} from 'vee-validate';
import {
  PropType,
  computed,
  onMounted,
  ref,
  toRef,
  watch,
} from 'vue';

const emits = defineEmits<{
  (e: 'input', value: string): void;
  (e: 'blur', value: string): void;
  (e: 'change', value: string): void;
}>();

// TODO: add focused props to focus first input in form
const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    required: true,
  },
  step: {
    type: [Number, String],
    default: 1,
  },
  required: {
    type: Boolean,
    default: false,
  },
  focused: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  autocomplete: {
    type: String,
    default: 'on',
  },
  type: {
    type: String as PropType<'text' | 'password' | 'number' | 'email' | 'url' | 'tel'>,
    default: 'text',
    validator: (type: string) => type === 'text'
      || type === 'password' || type === 'number'
      || type === 'email' || type === 'url'
      || type === 'tel',
  },
  themeNo: {
    type: Number as PropType<1 | 2>,
    default: 2,
    validator: (type: number) => type === 1 || type === 2,
  },
  value: {
    type: String,
    default: '',
  },
  successMessage: {
    type: String,
    default: '',
  },
  placeholder: {
    type: String,
    default: '',
  },
  errorText: {
    type: String,
    default: '',
  },
  flag: {
    type: String,
    default: '',
  },
});

const isPasswordHidden = ref(true);
const isPasswordType = computed(() => props.type === 'password');

const computedType = computed(() => {
  if (isPasswordType.value && !isPasswordHidden.value) {
    return 'text';
  }
  return props.type;
});

const name = toRef(props, 'name');

// we don't provide any rules here because we are using form-level validation
// https://vee-validate.logaretm.com/v4/guide/validation#form-level-validation
const {
  value: inputValue,
  resetField,
  setState,
  errorMessage,
  handleBlur,
  handleChange,
  meta,
} = useField(name, undefined, {
  initialValue: props.value,
});

watch(() => props.value, () => {
  resetField({
    value: props.value,
  });
});

const inputEl = ref<HTMLInputElement | null>(null);

onMounted(() => {
  if (props.focused && inputEl.value) {
    inputEl.value.focus();
  }
});

function onKeyPress(e: KeyboardEvent): void {
  const isNumeric = e.key === '0' || parseInt(e.key, 10);

  if (props.type === 'tel' && !isNumeric && e.key !== 'Enter') {
    e.preventDefault();
  }
}

function onInput(e): void {
  emits('input', inputValue.value);
  handleChange(e);
}

function onBlur(e): void {
  setState({
    value: e.target.value.trim(),
  });

  emits('blur', inputValue.value);
  handleBlur(e);
}

function onChange(): void {
  emits('change', inputValue.value);
}

</script>

<!-- REFERENCE: https://codesandbox.io/s/custom-text-input-with-vee-validate-v4-8k9gy?from-embed=&file=/src/main.js -->

<style scoped lang="scss">

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
}

</style>
