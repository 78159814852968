<template>
  <div class="flex justify-center">
    <span class="my-auto text-xs">
      Powered by
    </span>
    <a
      class="pl-4"
      href="https://nerddevs.com/"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img
        :src="require('@/assets/nerddevs_orange_logo.png')"
        alt="nerddevs-logo"
        class="h-[20px] w-[102px]"
      >
    </a>
  </div>
</template>
