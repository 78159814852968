<template>
  <div
    class="px-4 py-2"
  >
    <p
      class="receiver w-fit"
    >
      Hello There!
    </p>
    <p
      class="receiver mt-2 w-fit"
    >
      How Can I Help You?
    </p>
  </div>
</template>

<style scoped lang="scss">

.receiver {
  @apply bg-[#EEEEEE] max-w-[75%] p-2 rounded-lg;
}

</style>
